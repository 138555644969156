export class DynamicModal {
    constructor(buttons, inputsIdSelectors = null, select2IdSelectors = null, addSelect2Option = null) {
        this.buttons = buttons;
        this.inputsIdSelectors = inputsIdSelectors;
        this.select2IdSelectors = select2IdSelectors;
        this.addSelect2Option = addSelect2Option;
        this.lastAddedValue = null;

        const that = this;
        $(document).on("click", this.buttons, function () {
            that.changeValues($(this));
        })
    }

    changeValues(button) {
        let url = button.attr('data-'  + "url")
        let method = button.attr('data-'  + "method")
        const modalForm = $(button.attr('data-'  + "form"));
        modalForm.attr("action", url);
        if (method !== undefined) {
            modalForm.find('input[name="_method"]').val(method);
        }
        if (this.inputsIdSelectors) {
            this.inputsIdSelectors.forEach(selector => {
                const changeValue = button.attr('data-'  + selector);
                const $input = modalForm.find(`#${selector}`);
                if ($input.attr('type') === 'checkbox') {
                    $input.prop('checked', !!changeValue);
                } else {
                    $input.val($.trim(changeValue)).trigger('change');
                }
            });
        }

        if (this.select2IdSelectors) {
            this.select2IdSelectors.forEach(selector => {
                const changeValue = button.attr('data-'  + selector);
                const select = modalForm.find(`#${selector}`);
                if (this.addSelect2Option) {
                    if (this.lastAddedValue)
                        modalForm.find(`option[value=${this.lastAddedValue}]`).remove();

                    this.newOption = new Option(button.attr('data-'  + this.addSelect2Option.name), button.attr('data-'  + this.addSelect2Option.id), true, true);
                    this.lastAddedValue = button.attr('data-'  + this.addSelect2Option.id);
                    select.append(this.newOption).trigger("change");
                } else {
                    select.select2().val(changeValue).trigger("change")
                }
            });
        }
    }
}
