require('./bootstrap');
require('select2');
require('jquery.redirect');

import '@chenfengyuan/datepicker';
import 'daterangepicker';
import tinymce from "tinymce";
import moment from 'moment';
import { DynamicModal } from './DynamicModal'
import Apexcharts from 'apexcharts'
import Sortable from 'sortablejs';
import interact from 'interactjs';
import Swal from 'sweetalert2';
import Dropzone from "dropzone/dist/dropzone";

require('tinymce/plugins/code');
require('../../node_modules/bootstrap-treeview-npm/dist/bootstrap-treeview.min');

window.interact = interact;
window.Swal = Swal;
window.moment = moment;
window.ApexCharts = Apexcharts;
window.Sortable = Sortable;
window.Dropzone = Dropzone;
window.Apex = {
    dataLabels: {
        enabled: false
    },
    stroke: {
        width: 2
    },
};

$(document).ready(() => {
    $(".select2-global").select2({
        width: '100%'
    });
    $(".select2-multiple-global").select2({
        width: '100%',
        tags: true
    });

    const $checkboxSwitch = $(".checkbox-switch");
    $checkboxSwitch.bootstrapSwitch();
    $checkboxSwitch.trigger('change.bootstrapSwitch');

    tinymce.init({
        selector: '.crud-richtext',
        external_plugins: {
            'bootstrap4grid': '/js/vendor/bootstrap4-grid-tinymce.js'
        },
        plugins: [
            'autoresize',
            "lists advlist autolink table template visualblocks link image media code bootstrap4grid"
        ],
        menubar: false,
        toolbar2: 'undo redo | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link unlink | image media | removeformat code table bootstrap4grid visualblocks',
        image_advtab: true,
        skin: localStorage.getItem('is-dark-mode') ? 'oxide-dark' : 'oxide',
        content_css: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css'
        ],
        relative_urls: false,
        remove_script_host: false
    });


    let wFirmaOptions = [];
    $('#addCompanyModal #company_id').select2({
        ajax: {
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                'Authorization': 'Bearer ' + $('meta[name="api_token"]').attr('content'),
            },
            url: '/api/companies/wFirmaSearch',
            dataType: 'json',
            success: function (data) {
                wFirmaOptions = data['results'];
            }
        },

        dropdownParent: $("#addCompanyModal"),
        minimumInputLength: 3,
    });


    $('#addCompanyModal #company_id').on('select2:select', function (e) {
        const selectedId = e.params.data.id;
        let selectedCompany;
        wFirmaOptions.forEach(optGroup => {
            if (optGroup.hasOwnProperty('children')) {
                optGroup.children.forEach(company => {
                    if (company.id === selectedId) {
                        let { text: optGroupName, api_company_id: apiCompanyId } = optGroup;
                        selectedCompany = {
                            ...company,
                            'optGroupName': optGroupName,
                            'apiCompanyId': apiCompanyId,
                        };
                    }
                })
            }
        });
        $('#addCompanyModal #api_company_id').val(selectedCompany.apiCompanyId);
    })


    $('#addCompanyModal #gus_nip').select2({
        ajax: {
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                'Authorization': 'Bearer ' + $('meta[name="api_token"]').attr('content'),
            },
            url: '/api/companies/gusByNipSearch',
            dataType: 'json'
        },

        dropdownParent: $("#addCompanyModal"),
        minimumInputLength: 2,
    });

    $('#locationSelect').select2({
        dropdownParent: $("#addDepartmentModal"),
    });

    $(document).on('click', '.hamburger.close-sidebar-btn', function () {
        if ($(this).hasClass('is-active')) {
            localStorage.setItem('closed-sidebar', true);
        } else {
            localStorage.removeItem('closed-sidebar');
        }
    });

    $('#toggle-theme-btn').click(function () {
        if (localStorage.getItem('is-dark-mode')) {
            localStorage.removeItem('is-dark-mode');
        } else {
            localStorage.setItem('is-dark-mode', true);
        }
        toggleDarkMode();
    })

    function toggleDarkMode() {
        const $logoSrc = $('.logo-src');
        if (localStorage.getItem('is-dark-mode')) {
            $('body').addClass('dark-mode');
            $('.app-header').addClass('bg-dark header-text-light');
            $('.app-sidebar').addClass('bg-dark sidebar-text-light');
            $logoSrc.attr('src', $logoSrc.data('logo_dark_mode'));
            $('#toggle-theme-btn').addClass('btn-dark').removeClass('off');
            if (window.chart1) {
                window.chart1.updateOptions({
                    theme: { mode: 'dark' },
                    grid: { borderColor: '#252930' },
                    xaxis: { axisTicks: { color: '#252930' }, axisBorder: { color: '#252930' } }
                })
            }
            if (window.chart2) {
                window.chart2.updateOptions({ theme: { mode: 'dark' }, grid: { borderColor: '#252930' } })
            }
            if (window.invoiceChart) {
                window.invoiceChart.updateOptions({
                    theme: { mode: 'dark' },
                    grid: { borderColor: '#252930' },
                    xaxis: { axisTicks: { color: '#252930' }, axisBorder: { color: '#252930' } }
                })
            }
            if (window.chartCircles) {
                window.chartCircles.forEach(function (chart) {
                    chart.updateOptions({ theme: { mode: 'dark' }, grid: { borderColor: '#252930' } })
                })
            }
        } else {
            $('body').removeClass('dark-mode');
            $('.app-header').removeClass('bg-dark header-text-light');
            $('.app-sidebar').removeClass('bg-dark sidebar-text-light');
            $logoSrc.attr('src', $logoSrc.data('logo'));
            $('#toggle-theme-btn').addClass('btn-light').addClass('off');
            if (window.chart1) {
                window.chart1.updateOptions({
                    theme: { mode: 'light' },
                    grid: { borderColor: '#e9ecef' },
                    xaxis: { axisTicks: { color: '#e9ecef' }, axisBorder: { color: '#e9ecef' } }
                })
            }
            if (window.chart2) {
                window.chart2.updateOptions({ theme: { mode: 'light' }, grid: { borderColor: '#e9ecef' } })
            }
            if (window.invoiceChart) {
                window.invoiceChart.updateOptions({
                    theme: { mode: 'light' },
                    grid: { borderColor: '#e9ecef' },
                    xaxis: { axisTicks: { color: '#e9ecef' }, axisBorder: { color: '#e9ecef' } }
                })
            }
            if (window.chartCircles) {
                window.chartCircles.forEach(function (chart) {
                    chart.updateOptions({ theme: { mode: 'light' }, grid: { borderColor: '#e9ecef' } })
                })
            }
        }
    }

    $('table.table').on('click', 'thead #check-all', function () {
        const $checkAll = $(this);
        $checkAll.closest('table').find('tbody input[type="checkbox"]').prop('checked', $checkAll.is(':checked'));
    }).on('change', 'input[type="checkbox"]', function () {
        const isChecked = !!$(this).closest('table').find('tbody input[type="checkbox"]:checked').length;
        $('#btn-more-labels').toggle(isChecked);
    });
    $('body').on('click', '#btn-more-labels button', function () {
        let devices = [];
        $('table.table').find('tbody input[type="checkbox"]:checked').each(function (index, element) {
            devices.push($(element).val());
        });
        if (this.id === 'change-type-btn') {
            $('#changeTypeForm input[name="device"]').val(devices);
        } else {
            $.redirect($(this).attr('data-href'), {
                device: devices,
                _token: $('meta[name="csrf-token"]').attr('content')
            }, 'POST');
        }

    });

    const editLocationDynamicModal = new DynamicModal(
        ".edit-location-button",
        ["name"],
    )

    const editDepartmentDynamicModal = new DynamicModal(
        ".edit-department-button",
        ["name-edit"],
        ["location"],
    )

    const editPriceDynamicModal = new DynamicModal(
        ".edit-price-button",
        ["name", "cost"],
    )

    const editCompanyPriceDynamicModal = new DynamicModal(
        ".edit-company-price-button",
        ["old_price_id", "change_cost"],
        ["change_price_id"],
        { name: "name", id: "old_price_id" },
    )

    const editTicketDynamicModal = new DynamicModal(
        ".btn-edit-ticket, .btn-add-ticket",
        ["time_desc", "subject", "billing_type", "company_id", 'freshservice_ticket_agent_id', "timespent", "executed_at", "status", "billable", 'internal_status'],
    )
    const licenseMappingModal = new DynamicModal(
        ".btn-license-mapping",
        ["license", "software_id"],
    )
    const companyMappingModal = new DynamicModal(
        ".btn-company-mapping",
        ["company", "techdata_customer_id"],
    )
    const licenseCompanyModal = new DynamicModal(
        ".edit-license-company-button",
        ["license_id", "quantity"],
    )

    const licenseCompanyKeyModal = new DynamicModal(
        ".edit-license-keys-button",
        ["license_company_id"],
    )

    const addRecommendationModal = new DynamicModal(
        ".add-recommendation-button",
        ["recommendation_type"],
    )

    const editAuditDeviceModal = new DynamicModal(
        ".audit-device-edit-btn",
        ["device_id", "notes", "status"]
    )

    const editCompanyDeviceModal = new DynamicModal(
        ".edit-device-company-button",
        ["device_type", "device_type_preview", "quantity"]
    )
    const editCompanyUserModal = new DynamicModal(
        ".edit-user-company-button",
        ["user_role", "user_role_preview", "quantity"]
    )
    const locationIpModal = new DynamicModal(
        ".edit-location-ip-btn, .add-location-ip-btn",
        ["ip"]
    )
    const offboardingEmailModal = new DynamicModal(
        ".add-offboarding-email-btn, .edit-offboarding-email-btn",
        ["email"]
    )
    const replacementDeviceElementModal = new DynamicModal(
        ".btn-replacement-device-component-edit, .btn-replacement-device-component-add",
        ["name"]
    )
    const onboardingModal = new DynamicModal(
        ".btn-accept-onboarding",
    )
    const addDeviceToServiceManually = new DynamicModal(
        ".btn-add-device-to-service-manually, .btn-edit-device-to-service",
        ["company_id", "technician_id", "to_employee_id", "from_employee_id", "location", "step", "notes", "realized_at", "device_id"]
    )

    const editInternetModal = new DynamicModal(
        ".edit-internet-button, .create-internet-button",
        ["operator_name", "operator_customer_id", "connection_speed_in_agreement", "connection_speed_real", "connection_type", "agreement_date_from", "agreement_date_to", "account_name", "account_phone", "account_email", "location_id"]
    )

    const officeTimesheetExport = new DynamicModal(
        ".btn-office-timesheet-export"
    )

    window.changeFormActionUrl = function (formModalId, callback = null) {
        const url = $(this).data('url');
        const form = $(formModalId).find('form')
        if (callback) {
            callback.apply(this, [url, form]);
        }
        form.attr('action', url);
    }
});
